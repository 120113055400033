<template>
  <v-container fluid>
    <v-row>
      <v-col cols="5">
        <v-toolbar dense flat>
          <v-text-field
            hide-details
            prepend-icon="mdi-magnify"
            single-line
            placeholder="Сервис поиска не добавлен"
            disabled
          ></v-text-field>
          <v-menu open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-filter{{ options.status ? '' : '-outline' }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(status, index) in Object.keys(feedbacksStatuses)"
                :key="index"
                @click="onFilterItemClick(status)"
              >
                <v-list-item-title>{{ feedbacksStatuses[status] }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <div v-if="options.status">
          <v-chip
            class="ma-2"
            close
            color="primary"
            text-color="white"
            @click:close="onFilterItemClick(null)"
          >
            <v-icon left>
              mdi-filter-outline
            </v-icon>
            {{ feedbacksStatuses[options.status] }}
          </v-chip>
        </div>
        <div v-if="!data.length" class="my-8">
          <p class="text--secondary text-center">Список пуст</p>
        </div>
        <v-list v-else>
          <v-list-item-group
            v-model="currentProductIndex"
            color="teal"
          >
            <template v-for="(item, index) in data">
              <v-list-item
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.product && item.product.category" />
                  <v-list-item-subtitle v-text="item.product && item.product.name" />
                </v-list-item-content>
                <v-list-item-icon class="align-center">
                  {{ item.rating }}&nbsp;&nbsp;
                  <v-icon color="amber">
                    mdi-star
                  </v-icon>
                  <v-badge
                    :content="getProductCommentsCount(item)"
                    :value="getProductCommentsCount(item)"
                    color="deep-purple darken-3"
                    inline
                  >
                  </v-badge>
                </v-list-item-icon>
              </v-list-item>

              <v-divider
                v-if="index < data.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-pagination
          v-if="data.length"
          v-model="options.page"
          :length="totalPages"
          class="my-4"
          @input="onChangePage"
        ></v-pagination>
      </v-col>
      <v-col cols="7" style="height: 85vh; overflow: auto">
        <v-container v-if="currentProductIndex === undefined" fill-height fluid>
          <v-row align="center"
                 justify="center">
            <p class="text--secondary">Выберите продукт из списка</p>
          </v-row>
        </v-container>
        <product-detail
          v-else
          :comments="data[currentProductIndex].comments"
          :product-name="data[currentProductIndex].product.name"
          @updateComment="updateComment"
          @deleteComment="deleteComment"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ProductDetail from '@/views/crm/feedbacks/components/ProductDetail.vue';

export default {
  name: 'index',
  components: { ProductDetail },
  data() {
    return {
      data: [],
      totalElements: 0,
      totalPages: 0,
      // url: '/crm/product/feedback/grouped-by-product',
      url: '/crm/product/feedback/with-rating',
      options: {
        page: 1,
        size: 10,
        sort: 'created,desc',
        status: null,
      },
      currentProductIndex: undefined,
    };
  },
  computed: {
    feedbacksStatuses() {
      return this.$store.getters['entity/getEntity']('feedbacksStatuses');
    },
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    onFilterItemClick(value) {
      this.currentProductIndex = undefined;
      this.options.page = 1;
      this.options.status = value;
      this.onChangeData(this.options);
    },
    onChangePage(page) {
      this.currentProductIndex = undefined;
      this.options.page = page;
      this.onChangeData(this.options);
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions, page: parseInt(resultOptions.page, 10) };
      this.getTableData();
    },
    async getTableData() {
      // const { page, size, sort } = this.$route.query;
      const formattedUrl = this.url;
      this.$loading(true);
      this.$javaService.get(formattedUrl, {
        params: { ...this.$route.query, page: this.$route.query.page - 1 },
      }).then((response) => {
        // console.log('response', response);
        this.totalElements = response.recordsFiltered;
        this.totalPages = response.totalPages;
        this.data = response.content;
      }).finally(() => {
        this.$loading(false);
      });
    },
    getProductCommentsCount({ commentsTotalCount, commentsCountWithAnswers }) {
      // if (comments) {
      //   const answeredCount = comments.reduce((acc, cur) => acc + (cur.sellerAnswer ? 0 : 1), 0);
      //   return `${comments.length} / ${answeredCount}`;
      // }
      if (commentsCountWithAnswers || commentsTotalCount) {
        return `${commentsTotalCount} / ${commentsCountWithAnswers}`;
      }
      return 0;
    },
    updateComment({ status, index, moderationComment }) {
      const comment = this.data[this.currentProductIndex].comments[index];
      comment.status = status;
      const { sellerAnswer } = comment;
      sellerAnswer.moderationComment = moderationComment;
    },
    deleteComment(index) {
      const item = this.data[this.currentProductIndex];
      item.comments.splice(index, 1);
      item.commentsTotalCount -= 1;
      // this.getTableData();
    },
  },
};
</script>

<style scoped>

</style>
