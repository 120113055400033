<template>
  <section>
    <v-row align="center" class="mx-0 my-4">
      <p class="text-h6 text--primary">
        {{ productName }}
      </p>
    </v-row>
    <v-card v-for="(feedbackItem, index) in comments" :key="index" class="mb-4">
      <v-card-text>
        <v-row class="mx-0" no-gutters>
          <v-col>
            <v-alert
              text
              dense
              color="teal darken-1"
              :icon="getFeedbackIcon(feedbackItem)"
              border="left"
              class="ma-0"
            >
              <v-row>
                <v-col class="grow">
                  <p v-if="feedbackItem.comment" class="ma-0" style="word-break: break-all">
                    {{ feedbackItem.comment }}
                  </p>
                </v-col>
                <v-col class="shrink">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="onFeedbackRemove(feedbackItem.id, index)"
                        :disabled="canDelete"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Удалить отзыв</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-rating
                :value="feedbackItem.rating"
                color="amber"
                dense
                half-increments
                readonly
              ></v-rating>
              <p class="ma-0">
                {{ new Date(feedbackItem.created).toLocaleString() }}
              </p>
            </v-alert>
          </v-col>
        </v-row>

        <v-expand-transition>
          <div v-show="showIds.includes(feedbackItem.id)">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-account
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ feedbackItem.user.userName }}
                  ({{ feedbackItem.user.phoneNumber }})
                </v-list-item-title>
                <v-list-item-subtitle>Username (Номер телефона)</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="getFullName(feedbackItem.user)">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ getFullName(feedbackItem.user) }}
                </v-list-item-title>
                <v-list-item-subtitle>Имя Фамилия</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset class="my-2"></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-store
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="feedbackItem.supplier">
                  {{ feedbackItem.supplier.name }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  <i>Отсутствует</i>
                </v-list-item-title>
                <v-list-item-subtitle>Продавец</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-alert
              v-if="feedbackItem.sellerAnswer"
              text
              dense
              color="teal"
              icon="mdi-forum"
              border="left"
            >
              <p style="word-break: break-all">{{ feedbackItem.sellerAnswer.comment }}</p>
              <p class="ma-0">Дата ответа: {{ new Date(feedbackItem.created).toLocaleString() }}</p>
              <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
              <v-sheet
                v-if="[2, 4].includes(feedbackItem.status)"
                elevation="4"
                class="pa-4 mb-4"
                style="margin-left: -36px;"
              >
                <p v-if="feedbackItem.status === 4" class="text--secondary mb-2">
                  Комментарий модератора: <b>{{ feedbackItem.sellerAnswer.moderationComment }}</b>
                </p>
                <v-row v-if="feedbackItem.status === 2" align="stretch" no-gutters>
                  <v-col class="shrink">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          icon
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          @click="manageAnswer(feedbackItem, 3, index)"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </template>
                      <span>Принять</span>
                    </v-tooltip>
                  </v-col>
                  <div class="mr-4 my-1" style="border-left: 1px solid rgba(0,0,0,0.25)" />
                  <v-col class="grow">
                    <v-textarea
                      v-model="feedbackItem.moderationComment"
                      hide-details
                      solo
                      flat
                      auto-grow
                      rows="2"
                      style="max-height: 150px; overflow: auto"
                      label="Причина отклонения"
                      placeholder="Укажите причину, комментарий"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col class="shrink">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          icon
                          color="warning"
                          v-bind="attrs"
                          v-on="on"
                          @click="manageAnswer(feedbackItem, 4, index)"
                        >
                          <v-icon>mdi-cancel</v-icon>
                        </v-btn>
                      </template>
                      <span>На модерацию</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-alert>

            <v-divider inset class="my-4"></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-comment-quote
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ getFeedbackStatus(feedbackItem.status) }}
                </v-list-item-title>
                <v-list-item-subtitle>Статус</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset class="mt-4 mb-8"></v-divider>
            <p
              v-if="feedbackItem.mediaFiles && feedbackItem.mediaFiles.length"
              class="text--secondary mx-4"
            >
              Медиа файлы
            </p>
            <v-row class="mx-0">
              <v-col v-for="file in feedbackItem.mediaFiles" :key="file.id" cols="4">
                <v-card class="pa-2" outlined tile>
                  <v-img
                    :src="getImageURL(file.path)"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="200px"
                  >
                    <v-card-text v-text="file.fileName"></v-card-text>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
        <v-btn block text @click="showFeedbackDetail(feedbackItem.id)">
          Показать {{ showIds.includes(feedbackItem.id) ? "меньше" : "больше" }}
        </v-btn>
      </v-card-text>
    </v-card>
    <v-dialog v-model="fbDeleteDialog" max-width="500">
      <v-card>
        <v-card-title>
          Удалить отзыв
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="moderationDeleteComment"
            label="Укажите причину удаления"
            hint="* Обязательное поле"
            persistent-hint
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            text
            @click="
              fbDeleteDialog = false;
              deletingFeedback = null;
            "
          >
            Отмена
          </v-btn>
          <v-btn color="primary" :disabled="!moderationDeleteComment" @click="removeFeedback">
            Подтвердить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import getEnv from "@/utils/env";
import { mapState } from "vuex";

export default {
  name: "ProductDetail",
  props: {
    productName: {
      type: String,
      default: "",
      required: true
    },
    comments: {
      type: Array,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      showIds: [],
      fbDeleteDialog: false,
      deletingFeedback: null,
      moderationDeleteComment: null
    };
  },
  computed: {
    ...mapState(["user"]),
    canDelete() {
      return ["ROLE_CHIEF", "ROLE_CATEGORY_MANAGER_NON_FOOD"].some(role =>
        this.user.roles.includes(role)
      );
    }
  },
  methods: {
    getImageURL(path) {
      return `${getEnv("VUE_APP_URL_MINIO")}/ecom/${path}`;
      // const url = await this.getImageByPath(path);
    },
    getFullName(user) {
      if (user) {
        return user.firstName && user.lastName;
      }
      return "";
    },
    getFeedbackStatus(status) {
      if (status) {
        return this.$store.getters["entity/getEntity"]("feedbacksStatuses")[status];
      }
      return "Статус неизвестен";
    },
    getFeedbackIcon({ status }) {
      switch (status) {
        case 1:
          return "mdi-comment-outline";
        case 2:
          return "mdi-comment-eye-outline";
        case 3:
          return "mdi-comment-check-outline";
        case 4:
          return "mdi-comment-alert-outline";
        default:
          return "mdi-alert";
      }
    },
    manageAnswer({ sellerAnswer, moderationComment }, status, index) {
      if (status === 4 && !moderationComment) {
        return;
      }
      const params = {
        moderationComment,
        feedbacksId: sellerAnswer.id,
        status
      };
      this.$ratingService
        .put("/api/Moderation/seller", null, {
          params
        })
        .then(() => {
          this.$emit("updateComment", {
            status,
            index,
            moderationComment
          });
        });
    },
    onFeedbackRemove(id, index) {
      this.fbDeleteDialog = true;
      this.deletingFeedback = { id, index };
    },
    removeFeedback() {
      const url = `/api/Feedbacks/delete/${this.deletingFeedback.id}?ModerationComment=${this.moderationDeleteComment}`;
      this.$ratingService.delete(url).then(response => {
        if (response && response.succeeded) {
          this.$emit("deleteComment", this.deletingFeedback.index);
          this.fbDeleteDialog = false;
          this.deletingFeedback = null;
        }
      });
    },
    showFeedbackDetail(id) {
      const findIndex = this.showIds.findIndex(x => x === id);
      if (findIndex > -1) {
        this.showIds.splice(findIndex, 1);
      } else {
        this.showIds.push(id);
      }
    }
  }
};
</script>

<style scoped></style>
